import { Poll, Vote, Votes } from "../types";
import { initalAuthState } from "./AuthContext";
import { fireBaseApp } from "./base";
import "firebase/compat/database";

export const logout = async (onSuccess) => {
  try {
    await fireBaseApp.auth().signOut();
    onSuccess(initalAuthState);
  } catch (err) {
    console.log(err);
  }
};

export const getPollByUuid = async (pollUuid: string) => {
  const db = fireBaseApp.database().ref(`pollData/${pollUuid}`);
  return db.once("value").then((snapshot) => {
    return snapshot.val();
  });
};

export const getUserPolls = async (): Promise<
  { [k: string]: Poll } | undefined
> => {
  const user = fireBaseApp.auth().currentUser;
  if (user) {
    const db = fireBaseApp.database().ref(`userPolls/${user.uid}`);
    return db.once("value").then((snapshot) => {
      return snapshot.val();
    });
  }
};

export const submitVote = async (poll: Poll, votes: Votes) => {
  const pollDb = fireBaseApp.database().ref(`pollData/${poll.id}`);
  const userDb = fireBaseApp
    .database()
    .ref(`userPolls/${poll.authorId}/${poll.id}`);

  try {
    const pollSnapshot = await pollDb.once("value");
    if (!pollSnapshot.exists()) return false;
    const snapshotVotes = Array.from(pollSnapshot.val().votes || []);
    console.log(snapshotVotes);
    console.log(votes);
    let pollUpdateResults = false;
    let userUpdateResults = false;
    await pollDb.update(
      {
        votes: [...snapshotVotes, ...votes],
      },
      (err) => (pollUpdateResults = err ? false : true)
    );
    await userDb.update(
      {
        votes: [...snapshotVotes, ...votes],
      },
      (err) => (userUpdateResults = err ? false : true)
    );
    return pollUpdateResults && userUpdateResults;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const closePoll = async (pollId: Poll["id"]) => {
  const user = fireBaseApp.auth().currentUser;

  if (!user) {
    return false;
  }

  const pollDb = fireBaseApp.database().ref(`pollData/${pollId}`);
  let pollUpdateResults = false;
  let userUpdateResults = false;
  try {
    const pollSnapshot = await pollDb.once("value");
    if (!pollSnapshot.exists()) return false;
    await pollDb.update(
      { votingEnded: true },
      (err) => (pollUpdateResults = err ? false : true)
    );

    if (!pollUpdateResults || pollSnapshot.val().authorId !== user.uid)
      return false;

    const userDb = fireBaseApp
      .database()
      .ref(`userPolls/${user.uid}/${pollId}`);
    await userDb.update(
      { votingEnded: true },
      (err) => (userUpdateResults = err ? false : true)
    );
    return pollUpdateResults && userUpdateResults;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const reopenPoll = async (pollId: Poll["id"]) => {
  const user = fireBaseApp.auth().currentUser;
  const pollDb = fireBaseApp.database().ref(`pollData/${pollId}`);

  if (!user) return false;

  try {
    const pollSnapshot = await pollDb.once("value");
    if (!pollSnapshot.exists()) return false;

    await pollDb.update({ votingEnded: false });

    if (pollSnapshot.val().authorId !== user.uid) return false;

    const userDb = fireBaseApp
      .database()
      .ref(`userPolls/${user.uid}/${pollId}`);

    await userDb.update({ votingEnded: false });

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const savePoll = async (poll: Poll) => {
  const user = fireBaseApp.auth().currentUser;
  if (user) {
    const newPoll: Poll = {
      authorId: user.uid,
      id: poll.id,
      pollTitle: poll.pollTitle,
      votingEnded: poll.votingEnded,
      createdAt: poll.createdAt.toString() || new Date().toString(),
      movieOptions: poll.movieOptions,
      votes: poll.votes || [],
      authorName: poll.authorName,
      public: poll.public,
      votesPerSubmission: poll.votesPerSubmission || 1,
    };
    const db = fireBaseApp.database().ref(`userPolls/${user.uid}/${poll.id}`);
    return db
      .once("value")
      .then((snapshot) => {
        if (!snapshot.exists()) {
          db.set(newPoll, async (userErr) => {
            if (!userErr) {
              const polldb = fireBaseApp.database().ref(`pollData/${poll.id}`);
              return polldb.once("value").then((snapshot) => {
                if (!snapshot.exists()) {
                  return polldb.set(newPoll, (pollErr) => {
                    if (!pollErr) {
                      return true;
                    }
                    return false;
                  });
                }
              });
            } else {
              return false;
            }
          });
          return true;
        }
      })
      .catch((err) => false);
  }
  return false;
};

export const deletePoll = async (pollId: Poll["id"]) => {
  const user = fireBaseApp.auth().currentUser;
  if (user) {
    const db = fireBaseApp.database().ref(`userPolls/${user.uid}/${pollId}`);
    return db
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          db.remove(async (userErr) => {
            if (!userErr) {
              const polldb = fireBaseApp.database().ref(`pollData/${pollId}`);
              return polldb.once("value").then((snapshot) => {
                if (snapshot.exists()) {
                  return polldb.remove((pollErr) => {
                    if (!pollErr) {
                      return true;
                    }
                    return false;
                  });
                }
              });
            } else {
              return false;
            }
          });
          return true;
        }
      })
      .catch((err) => false);
  }
  return false;
};
