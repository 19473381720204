import React, { useState, ReactNode, useContext, useEffect } from "react";

import { fireBaseApp } from "./base";
import { logout } from "./authActions";

export const initalAuthState = {
  authStatusReported: false,
  isUserSignedIn: false,
  username: null as string | null,
  avatarUrl: "",
  userEmail: null as string | null,
  uid: "",
};

export const AuthContext = React.createContext({
  auth: initalAuthState,
  logout: () => {},
  signingIn: false,
});

type AuthContextProvider = {
  children: ReactNode;
};

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: AuthContextProvider) => {
  const [authState, setAuthState] = useState(initalAuthState);
  const [signingIn, setSigningIn] = useState(false);
  const onLogoutSuccess = () => logout(setAuthState);

  useEffect(() => {
    const unsubscribe = fireBaseApp.auth().onAuthStateChanged((user) => {
      setSigningIn(true);
      if (user) {
        setAuthState({
          authStatusReported: true,
          isUserSignedIn: !!user,
          uid: user.uid,
          username: user.displayName,
          avatarUrl: user.photoURL || "",
          userEmail: user.email || user.providerData[0].email,
        });
        setSigningIn(false);
      } else {
        setAuthState(initalAuthState);
        setSigningIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth: authState,
        logout: onLogoutSuccess,
        signingIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
